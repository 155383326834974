import './demo.css';
import demoImg from '../../assets/demo.png';
import { Cta, Feature } from '../../components';
import {useTranslation} from "react-i18next";
export default function Demo (){
    const [t] = useTranslation();
    return (
      <div className='cenware__demo section__padding' id="demo">
        <div className='cenware__demo-image'>
          <Feature
              title={t('demo.feature.title')}
              text={t('demo.feature.text')}
          />
          <img src={demoImg as unknown as string} alt="demoImage" />
        </div>
        <div className='cenware__demo-content'>
          <div className='cenware__demo-content-text'>
            <h4>{t('demo.content.subtitle')}</h4>
            <h1 className='gradient__text'>{t('demo.content.title')}</h1>
            <p>{t('demo.content.paragraph')}</p>
          </div>
          <Cta />
          <div className='cenware__demo-content-box'>
          </div>
        </div>
      </div>
    );
}
