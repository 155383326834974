import React from 'react';
import App from './App';
import './index.css'
import { createRoot } from 'react-dom/client';
import './i18n'

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
    <React.StrictMode>
        <React.Suspense fallback="loading">
            <App />
        </ React.Suspense>
    </React.StrictMode>
);