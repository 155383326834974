import './cta.css';
import {useTranslation} from "react-i18next";

export default function Cta (){
    const [t] = useTranslation();

    const handleClick = () => {
        window.open(
            'https://dashboard.cenware.net',
            '_blank' // <- This is what makes it open in a new window.
        );
    }

    return (
      <div className='cenware__cta'>
        <div className='cenware__cta-content'>
          <p>{t('cta.paragraph')}</p>
          <h3>{t('cta.title')}</h3>
        </div>
        <div className='cenware__cta-btn'>
          <button type='button' onClick={handleClick}>{t('cta.button')}</button>
        </div>
      </div>
    );
}