import { Footer, Solutions, Header, Demo, Contact} from './containers';
import { Navbar } from './components';
import CookieConsent from 'react-cookie-consent';
import './app.css'
function App(){
    return (
      <div className="App">
        <div className='gradient__bg'>
            <Navbar />
            <Header />
        </div>
        <Solutions />
        <div className='gradient__bg2'>
          <Demo />
        </div>
        <Contact />
        <Footer />
        <CookieConsent enableDeclineButton>Usamos cookies para garantizar el correcto funcionamiento y seguridad de nuestros sitios web, así podemos ofrecerte una mejor experiencia de usuario. Al hacer clic en Aceptar, aceptas el uso de estas cookies para publicidad y analíticas. Puedes cambiar la configuración de las cookies en cualquier momento. Para obtener más información, lee nuestra Política de cookies.</CookieConsent>
      </div>
    )
}

export default App
