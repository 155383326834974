import React from 'react';
import './footer.css';
import logo from  '../../assets/logo.png';
import { MdFacebook } from "react-icons/md";
import { AiFillInstagram, AiFillTwitterCircle/*, AiFillYoutube */} from "react-icons/ai";
import {useTranslation} from "react-i18next";

export default function Footer() {
    const [t] = useTranslation();
    return (
      <div className='cenware__footer section__padding' id="footer">
        <div className='cenware__footer-links'>
          <div className='cenware__footer-links_logo'>
            <img src={logo as unknown as string} alt="logo"/>
            <p>{t('footer.copyright')}</p>
          </div>
          <div className='cenware__footer-links_div'>
            <h2>{t('footer.links.title')}</h2>
            <p>{t('footer.links.home')}</p>
            <p>{t('footer.links.demo')}</p>
            <p>{t('footer.links.contact')}</p>
          </div>
          <div className='cenware__footer-links_div'>
            <h2>{t('footer.security.title')}</h2>
            <p>{t('footer.security.privacy')}</p>
            <p>{t('footer.security.terms')}</p>
            <p>{t('footer.security.cookies')}</p>
          </div>
          <div className='cenware__footer-links_network'>
            <h2 className="">{t('footer.connect')}</h2>
            <div className="icons">
                <a href="https://www.facebook.com/profile.php?id=61556397616774" target='_blank' rel="noreferrer">
                    <MdFacebook size={32} />
                </a>
                <a href="https://twitter.com/cenware" target="_blank" rel="noreferrer">
                    <AiFillTwitterCircle size={32} />
                </a>
                <a href="https://www.instagram.com/cenware/" target="_blank" rel="noreferrer">
                    <AiFillInstagram size={32} />
                </a>
                {/* <a href="#youtube">
                  <AiFillYoutube size={32} /> 
                </a>*/}
            </div>
          </div>
        </div>
      </div>
    );
}