import {useState} from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from "../../assets/logo.png"
import './navbar.css';
import {useTranslation} from "react-i18next";

const Menu = () => {
    const [t] = useTranslation();
    return(
        <>
            <p><a href="#home">{t('navbar.home')}</a></p>
            <hr />
            <p><a href="#solutions">{t('navbar.solutions')}</a></p>
            <hr />
            <p><a href="#demo">{t('navbar.demo')}</a></p>
            <hr />
            <p><a href="#contact">{t('navbar.contact')}</a></p>
        </>
    );
}

export default function Navbar(){
    const [toggleMenu, setToggleMenu] = useState(false);

    return (
      <div className='cenware__navbar'>
          <div className='cenware__navbar-logo'>
            <img src={logo as unknown as string} alt="Logo" />
          </div>
        <div className='cenware__navbar-links'>
          <div className='cenware__navbar-links_container'>
            <Menu />
          </div>
        </div>
        <div className='cenware__navbar-menu'>
          {toggleMenu
            ?<RiCloseLine color='#5e6578' size={27} onClick={() =>
              setToggleMenu(false)}/>
            :<RiMenu3Line color='#5e6578' size={27} onClick={() =>
              setToggleMenu(true)}/>
          }
          {toggleMenu && (
            <div className='cenware__navbar-menu_container scale-up-hor-right'>
              <div className='cenware__navbar-menu_container-links'>
                <Menu />
              </div>
            </div>
          )}
        </div>
      </div>
    );
}


