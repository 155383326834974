import React, {useState} from 'react';
import './contact.css';
import { Feature } from '../../components';
import axios from 'axios';
import {useTranslation} from "react-i18next";

interface ContactProps {}

const Contact: React.FC<ContactProps> = () => {
  const [t] = useTranslation();
  const [state, setState] = useState({
    name: '',
    last_name: '',
    email: '',
    phone_number: '',
    message: '',
    isSubmitting: false,
    errors: '',
    successMessage: '',
    errorMessage: ''
  });

  const validateFields = (): string => {
    const nameRegex: RegExp = /^[A-Za-z]+$/;
    const mailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex: RegExp = /^\d+$/;

    if (!state.name || !nameRegex.test(state.name))
      return t('contact.error.name');
    if (!state.last_name || !nameRegex.test(state.last_name))
      return t('contact.error.lastname');
    if (!state.email || !mailRegex.test(state.email))
      return t('contact.error.email');
    if (!state.phone_number || !phoneRegex.test(state.phone_number))
      return t('contact.error.phone');
    if (!state.message)
      return t('contact.error.message');

    return '';
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
      errors: validateFields(),
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateFields() === '') {
      setState((prevState) => ({ ...prevState, isSubmitting: true }));

      const formData = {
        name: state.name,
        last_name: state.last_name,
        email: state.email,
        phone_number: state.phone_number,
        message: state.message,
      };

      try {
        if (!process.env.REACT_APP_MAIL_ENDPOINT) {
          throw new Error('Mail server not defined.');
        }
        await axios.post(process.env.REACT_APP_MAIL_ENDPOINT, formData);

        setState((prevState) => ({
          ...prevState,
          successMessage: t('contact.sendState.success'),
          errorMessage: ''
        }));
      } catch (error) {
        setState((prevState) => ({
          ...prevState,
          successMessage: '',
          errorMessage: t('contact.sendState.error')
        }));
      } finally {
        setState((prevState) => ({
          ...prevState,
          isSubmitting: false,
          name: '',
          last_name: '',
          email: '',
          phone_number: '',
          message: ''
        }));
      }
    }
  };

  return (
      <div className='cenware__contact section__padding' id='contact'>
        <div className='cenware__contact-left'>
          <div className='cenware__contact-left-up'>
            <h1 className='gradient__text'>{t('contact.section.title')}</h1>
            <p>{t('contact.section.paragraph')}</p>
            <p>cenware@cenware.net</p>
            <p>+34 696 480 093</p>
          </div>
          <div className='cenware__contact-left-bottom'>
            <Feature
                title={t('contact.hiring.title')}
                text={t('contact.hiring.text')}
            />
            <Feature
                title={t('contact.support.title')}
                text={t('contact.support.text')}
            />
            <Feature
                title={t('contact.feedback.title')}
                text={t('contact.feedback.text')}
            />
          </div>
        </div>
        <div className='cenware__contact-right'>
          <div className='cenware__contact-right-form'>
            <h2>{t('contact.form.title')}</h2>
            <p>{t('contact.form.paragraph')}</p>
            <form onSubmit={handleSubmit}>
              <div className='cenware__contact-right-form__name'>
                <input placeholder={t('contact.form.name')} name='name' value={state.name} onChange={handleChange} />
                <input placeholder={t('contact.form.lastname')} name='last_name' value={state.last_name} onChange={handleChange} />
              </div>
              <div className='cenware__contact-right-form__mail'>
                <input placeholder={t('contact.form.email')} name='email' value={state.email} onChange={handleChange} />
              </div>
              <div className='cenware__contact-right-form__tlfn'>
                <input placeholder={t('contact.form.phone')} name='phone_number' value={state.phone_number} onChange={handleChange} />
              </div>
              <div className='cenware__contact-right-form__content'>
                <textarea placeholder={t('contact.form.message')} name='message' value={state.message} onChange={handleChange} />
              </div>
              <div className='cenware__contact_right-form__errors'>
                {state.errors && (
                    <p style={{ color: 'red', fontSize: '12px', fontWeight: '300' }}>{state.errors}</p>
                )}
              </div>
              <div className='cenware__contact-right-form__result'>
                {state.successMessage && <h3 style={{color: "blue"}}>{state.successMessage}</h3>}
                {state.errorMessage && <h3 style={{color: "red"}}>{state.errorMessage}</h3>}
              </div>
              <div className='cenware__contact-right-form__send'>
                <button type='submit' disabled={state.isSubmitting}>
                  {state.isSubmitting ? t('contact.form.button.sending') : t('contact.form.button.send')}
                </button>
              </div>
            </form>
            <div className='cenware__contact-right-form__policy'>
              <p>{t('contact.form.policy')}</p>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Contact;