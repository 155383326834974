import React from 'react';
import './header.css';
import metrics from '../../assets/metrics.png';
import statistics from '../../assets/stadistics.png';
import customers from '../../assets/customers.png';
import management from '../../assets/management.png';
import {useTranslation} from "react-i18next";

export default function Header(): JSX.Element {
    const {t} = useTranslation();

    return (
      <div className='cenware__header section__padding' id="home">
        <div className='cenware__header-content'>
          <div className='cenware__header-content-up'>
            <div className='cenware__header-content-up-text'>
              <h1 className='gradient__text'>{t('heading.title')}</h1>
              <p>{t('heading.paragraph')}</p>
            </div>
            <div className='cenware__header-content__management'>
                <img src={management as unknown as string} alt="management" />
            </div>
          </div>
          <div className='cenware__header-content-down'>
            <div className='cenware__header-content__metrics'>
              <img src={metrics as unknown as string} alt="metrics" />
            </div>

            <div className='cenware__header-content__customers'>
              <img src={customers as unknown as string} alt="customers" />
            </div>

            <div className='cenware__header-content__stadistics set-column'>
              <img src={statistics as unknown as string} alt="stadistics" />
            </div>
          </div>
        </div>
      </div>
    );
}

