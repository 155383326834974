import React from 'react';
import './solutions.css';
import { Feature } from '../../components';
import solutionsImg from '../../assets/solutions.png';
import {useTranslation} from "react-i18next";

export default function Solutions() {
    const [t] = useTranslation();
    return (
      <div className='cenware__solutions section__margin' id="solutions">
        <div className='cenware__solutions-feature'>
          <Feature
            title={t('solutions.solutions.title')}
            text={t('solutions.solutions.text')}
          />
        </div>
        <div className='cenware__solutions-heading'>
            <div className='cenware__solutions-heading-title'>
              <img src={solutionsImg as unknown as string} alt="demoImage" />
            </div>
            <Feature
              title={t('solutions.software.title')}
              text={t('solutions.software.text')}
            />
        </div>
        <div className='cenware__solutions-container'>
          <Feature
            title={t('solutions.management.title')}
            text={t('solutions.management.text')}
          />
          <Feature
            title={t('solutions.automation.title')}
            text={t('solutions.automation.text')}
          />
          <Feature
            title={t('solutions.web.title')}
            text={t('solutions.web.text')}
          />
        </div>
      </div>
    );
}