import React, { Component } from 'react';
import './feature.css'
 
interface FeatureProps {
  title : string;
  text : string | any[];
}

export class Feature extends Component<FeatureProps> {
  render() {
    const { title, text } = this.props;

    return (
      <div className='cenware__features-container__feature'>
        <div className='cenware__features-container__feature-title'>
          <div/>
          <h1>{title}</h1>
        </div>
        <div className='cenware__features-container_feature-text'>
          <p>{text}</p>
        </div>
      </div>
    );
  }
}

export default Feature;
